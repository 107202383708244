.navbar{
    background-color: rgba(0, 0, 0, 0.596) !important;
    padding: 18px 0px !important;
}
.active{
    background-color: rgb(0, 0, 0) !important;
    transition: .3s all ease-in;
}
.navLinkStyle{
    font-family: 'SemiBold';
    font-size: 18px;
    padding-right: 30px !important;
    color: #fff !important;
}
.navLinkStyle:hover{
    transition: .3s all ease-in;
    color: #C47C5A !important;
}
.styleAuto{
    margin-left: auto !important;
    margin-right: auto !important;
    flex-grow: initial !important;
}
.btnContact, .pageActive{
    padding: 14px 30px;
    background-color: #18988B;
    font-family: 'SemiBold';
    font-size: 15px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border-radius: 4px;
    text-transform: uppercase;
}
.btnContact:hover{
    transition: .5s all ease-in;
    background-color: #C47C5A;
    text-decoration: none;
    color: white !important;
}
.dView{
    display: none;
}
.styleMainLogo{
    height: 64px;
    max-width: 100%;
}
@media only screen and (max-width: 768px) {
    .mView{
        display: none !important;
    }
    .dView{
        display: block;
        margin-top: 18px;
        padding-bottom: 40px;
    }
    .styleMainLogo{
        margin-left: 15px;
        height: 46px;
    }
    .navbar{
        padding: 10px 0px !important;
    }
    .navbar-light .navbar-toggler-icon{
        background-image: url('../Assets/menu.svg') !important;
        height: 36px !important;
        margin-right: 5px;
    }
    .navLinkStyle{
        padding-right: 0px !important;
    }
  }