.firstFold{
    background-image: url('../Assets/home/mainBg.png');
	background-position: bottom 0;
	background-repeat: no-repeat;
	background-size: cover;
    margin-top: none;
    height: 100vh;
    overflow: auto;
}
.txtDivFirst{
    margin-top: 28vh;
}
.colorWhite{
    color: #fff;
}
.firstHead{
    font-family: 'Bold';
    font-size: 42px;
    line-height: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #17bdac;
    text-align: center;
    margin-bottom: 20px;
}
.firstSubhead{
    font-family: 'Medium';
    font-size: 32px;
    line-height: auto;
    display: flex;
    align-items: center;
    text-align: center;
    color: #e7e7e7;
}
.btnOutline{
    font-family: 'SemiBold';
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #fff;
    padding: 16px 36px;
    width: 200px;
    cursor: pointer;
    margin: 42px auto 0px auto;
}
.btnOutline:hover{
    transition: .4s all ease-in-out;
    background-color: #18988B;
    border: 1px solid #18988B;
    color: #fff;
    text-decoration: none;
}
.secondFold{
    margin: 100px auto;
}
.MainHead{
    font-family: 'Bold';
    font-size: 40px;
    color: #18988B;
    text-align: left;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.subHeadSecond{
    font-family: 'Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    line-height: auto;
    color: #fff;
}
.bannerLogoStyle{
    max-width: 100%;
    height: 140px;
    margin-bottom: 50px;
}
.styleSlider{
    height: 360px !important;
    margin-left: 50px;
}
.styleSliderRight{
    margin-left: 200px;
}
.borderStyle{
    border: 10px solid #000;
}
.mainContainerStyle{
    margin-top: 70px;
}
.titleV{
    position: absolute;
    bottom: -30px;
    left: -35px;
    -webkit-transform: rotate(
270deg);
    -moz-transform: rotate(270deg);
    transform: rotate(
270deg);
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    font-family: 'Medium';
    font-size: 28px;
    color: #fff;
}
.photographerCardImageSlide{
    max-width: 100%;
    height: auto;
}
.imagePhotographerSlide {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease;
}
.imagePhotographerSlide:hover {
    transform: scale(1);
}

.imagePhotographerSlide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease;
}

.imagePhotographerSlide:hover img {
    transform: scale(1.15);
    cursor: pointer;
}
.styleContainerSpace{
    margin-left: 20%;
}
.modal-content{
    background-color: #062A30 !important;
}
.disclaimerTitle{
    font-family: 'Bold';
    font-style: normal;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 32px !important;
    text-align: center;
    line-height: auto;
    color: #17bdac;
    margin-left: auto;
    margin-right: auto;
}
button.btn-close{
    display: none;
}
.paraModal{
    font-family: 'Medium';
    font-size: 20px;
    color: #fff;
    text-align: left;
}
.modal-footer{
    text-align: center !important;
    display: block !important;
}
.modalAgreeBtn{
    padding: 12px 40px !important;
    background-color: #fff !important;
    color: #062A30 !important;
    font-family: 'Bold';
    font-size: 18px;
    text-transform: uppercase;
    border: none !important;
}
.modalAgreeBtn:hover{
    background-color: #C47C5A !important;
    color: #fff !important;
}
@media only screen and (max-width: 768px) {
    .txtDivFirst{
        margin-top: 24vh;
    }
    .firstHead{
        line-height: 140%;
        font-size: 30px;
    }
    .MainHead{
        font-size: 32px;
        margin-bottom: 14px !important;
    }
    .subHeadSecond{
        font-size: 18px;
    }
    .bannerLogoStyle{
        height: 100px;
        margin-bottom: 30px;
    }
    .styleContainerSpace{
        margin-left: 10px;
    }
    .styleSlider{
        height: auto !important;
        width: fit-content !important;
        margin-left: auto;
    }
    .titleV{
        position: initial;
        transform: none;
        transform-origin: left;
        font-family: 'Medium';
        font-size: 22px;
        text-align: center;
        color: #fff;
    }
    .borderStyle{
        border: 5px solid #000;
    }
}
.styleSliderRight{
    display: none;
}

@media screen and (max-width: 2240px) and (min-width: 1024px) {
    .styleSliderRight{
        display: block !important;
    }
  }
  .videoDiv{
    padding: 0px 0px 80px 0px; 
  }
  .styleVideo{
    margin-left: auto;
    margin-right: auto;
    width: 80% !important;
    height: auto !important;
  }
  .centerAlign{
      text-align: center !important;
      margin-bottom: 60px;
  }