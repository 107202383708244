.photoExhibitionDiv{
    padding: 200px 0px 100px;
}
.profileImage{
    max-width: 100%;
    height: auto;
}
.namePhotographer{
    font-family: 'SemiBold';
    font-size: 24px;
    color: #fff;
    text-align: left; 
}
.aboutPhotographer{
    font-family: 'Medium';
    font-size: 16px;
    color: #D2D2D2;
    text-align: left;
}
.divider{
    margin: 36px 0px;
}
.line{
    width: 100%;
    border: 1px solid #EA9C77;
}
.dividerTxt{
    font-family: 'MediumItalic';
    font-size: 20px;
    text-align: center;
    color: #0AEED7;
}
.p-0{
    padding: 0px;
}
.firstImage, .spaceDown{
    margin-bottom: 30px;
}
.styleAdjust{
    max-width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 4px;
    /* object-fit: contain;
    height: 100%; */
}
.alignLeft{
    text-align: left;
}
@media only screen and (max-width: 768px) {
    .photoExhibitionDiv{
        padding-top: 160px;
    }
    .profileImage{
        height: 340px;
        margin-bottom: 30px;
    }
    .line{
        width: auto;
    }
    .styleBottomImg{
        margin-bottom: 30px;
    }
    .spaceDown{
        margin-bottom: 0px;
    }
}