.footerDiv{
    background-color: #000;
    padding: 120px 0px 0px 0px;
}
.paraFooter{
    font-family: 'Medium';
    text-align: left;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 32px;
}
.socialDiv{
    text-align: left;
    margin-top: 20px;
}
.socialIcon{
    height: 30px;
    margin-right: 26px;
    transition: all .3s ease-in-out;
}
.socialIcon:hover{
    transform: scale(1.2);
}
.footerHead{
    font-family: 'Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.03em;
    color: #18988B;
    text-transform: uppercase;
    margin-bottom: 12px;
}
.alignLinks{
    text-align: left;
}
.footerLinks{
    text-align: left;
    margin-bottom: 8px;
    font-family: 'Medium';
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #c5c5c5;
}
.footerLinks:hover{
    text-decoration: none;
    transition: .3s all ease-in;
    color: #EA9C77;
    cursor: pointer;
}
.copyrightDiv{
    background-color: #000;
    padding: 30px 0px;
    margin-top: 120px;
    border-top: 1px solid #ececec6e;
}
.copyrightTxt{
    color: #fff;
    text-align: center;
    font-family: 'Medium';
    font-size: 14px;
    letter-spacing: 0.01em;
    margin: 0px;
}
.centerStyle{
    text-align: left;
}
.footerLogoStyle{
    height: 110px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .footerLogoStyle{
        height: 98px;
        margin-bottom: 38px;
    }
    .centerStyle, .footerHead, .socialDiv{
        text-align: center;
    }
    .alignLinks{
        margin-bottom: 18px;
        text-align: center !important;
    }
    .footerLinks{
        text-align: center;
    }
    .footerDiv{
        padding-top:80px ;
    }
    .copyrightDiv{
        margin-top: 80px;
    }
}
.styleTag{
    color: #18988B ;
    text-decoration: none;
}
.styleTag:hover{
    text-decoration: none;
    color: #fff;
}