.zoomImageAbout{
    width: 100%;
    height: 95vh;
    padding-top: 100px;
}

#opening {
	position: relative;
	overflow: hidden;
}
.opening-image {
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    margin-top: -58px;
    margin-left: auto;
    z-index: 0;
    width:100%;
}
.colorWhite{
    color: #fff;
}

@-webkit-keyframes zooming {
    0% {-webkit-transform: scale(1);}
    100% {-webkit-transform: scale(1.4);}
}
@keyframes zooming {
    0% {transform: scale(1);}
    100% {transform: scale(1.4);}
}

.zooming {
	-webkit-animation-name: zooming;
	-webkit-animation-duration: 20s;
	-webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;	
	animation-name: zooming;
	animation-duration: 20s;
	animation-fill-mode: forwards;
    animation-iteration-count: infinite;
	animation-direction: alternate;
}
.aboutDivSecond{
    overflow-x: hidden;
    /* height: 1000px; */
    background-color: #010f11;
}
.p-0{
    padding: 0px;
}
.image {
    margin: 0 1em;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease;
}
.image:hover {
    transform: scale(.9);
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease;
}

.image:hover img {
    transform: scale(1.15);
}
.aboutContentDiv{
    padding: 100px 0px;
}
.aboutMainHead{
    font-family: 'Bold';
    font-size: 40px;
    color: #18988B;
    text-align: left;
    margin-bottom: 24px;
    text-transform: uppercase;
}
.bodyPara{
    font-family: 'Regular';
    font-size: 18px;
    line-height: 150%;
    color: #fff; 
    text-align: left;
    margin-bottom: 42px;
}
.txtRed, .txtGreen{
    font-family: 'Medium';
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 6px;
    color: #fff; 
}
.aboutSecondImage{
    width: 100%;
}
@media only screen and (max-width: 768px) {
    .aboutDivFirst{
        background-image: url('../Assets/about/aboutMain.png');
        height: 80vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    #opening{
        display: none !important;
    }
    .aboutMainHead{
        font-size: 32px;
    }
}