.exhibitionDivFirst{
    background-image: url('../Assets/exhibition/exhibitionBanner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 200px 0px 100px 0px;
}
.mainHeadExhibition{
    font-family: 'Bold';
    font-size: 40px;
    color: #18988B;
    text-align: left;
    margin-bottom: 24px;
    text-transform: uppercase;
}
.photographerDiv{
    padding: 100px 0px;
}
.bottomCard{
    padding: 14px 22px;
    position: absolute;
    width: 92.4%;
    bottom: 0px;
    border-radius: 4px;
    background-color: #18988bbd;
}
.bottomCard:hover{
    cursor: pointer;
    background-color: #18988b;
    transition: 0.6s ease;
}
.photographerCardImage{
    max-width: 100%;
    height: auto;
}
.photographerName{
    font-family: 'SemiBold';
    font-size: 22px;
    margin-bottom: 0px;
    color: #fff;
    text-align: left;
}
.imagePhotographer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease;
    border-radius: 4px;
}
.imagePhotographer:hover {
    transform: scale(1);
}

.imagePhotographer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease;
}

.imagePhotographer:hover img {
    transform: scale(1.15);
    cursor: pointer;
}
.spaceBottom{
    margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
    .imagePhotographer{
        margin-bottom: 30px;
    }
    .bottomCard{
        padding: 14px 22px;
        position: absolute;
        width: 93%;
        bottom: 30px;
        border-radius: 4px;
        background-color: #18988bbd;
    }
    .mainHeadExhibition{
        font-size: 32px;
    }
    .spaceBottom{
        margin-bottom: auto;
    }
}
